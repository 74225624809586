import { Device } from "@twilio/voice-sdk";
import { useMutation } from "react-query";
import { useState, useEffect } from "react";
import axios from "../config/axios";
import { ENDPOINTS } from "../config";

export default function useCall() {
  const [call, setCall] = useState({ instance: undefined, status: undefined });
  const [device, setDevice] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);

  const _listeners = {
    call: {
      accept: () => setCall((x) => ({ ...x, status: "accept" })),
      reject: () => setCall((x) => ({ ...x, status: "reject" })),
      disconnect: () => setCall((x) => ({ ...x, status: "disconnect" })),
      cancel: () => setCall((x) => ({ ...x, status: "cancel" })),
    },
  };

  // Get SDK Access Token Mutation
  const GSDKM = useMutation(() => axios.get(ENDPOINTS.com.sdk.twilio), {
    onSuccess: (response) => {
      if (response?.token) setAccessToken(response?.token);
    },
  });

  // Update Token
  const _updateDevice = (accessToken) => {
    if (!device) setDevice(new Device(accessToken));
    else {
      console.log("Updating device token");
      device.updateToken(accessToken);
      if (device._state === "unregistered" && device._state !== "registering") device.register();
    }
  };

  const _removeCallListeners = () => {
    call.instance?.off("accept", _listeners.call.accept);
    call.instance?.off("reject", _listeners.call.reject);
    call.instance?.off("disconnect", _listeners.call.disconnect);
    call.instance?.off("cancel", _listeners.call.cancel);
    return;
  };

  const _initiateCall = (newCall) => {
    newCall.on("accept", _listeners.call.accept);
    newCall.on("reject", _listeners.call.reject);
    newCall.on("disconnect", _listeners.call.disconnect);
    newCall.on("cancel", _listeners.call.cancel);
    setCall({ instance: newCall, status: newCall.status() });
    return;
  };

  // Update/Create Device upon new accessToken
  useEffect(() => {
    if (!accessToken) return;
    _updateDevice(accessToken);
  }, [accessToken]);

  const _makeCall = async (token) => {
    if (!token) return;
    try {
      const newCall = await device.connect({
        params: {
          callToken: token,
        },
      });
      if (call) _removeCallListeners();
      _initiateCall(newCall);
    } catch (error) {
      console.log(error);
    }
  };

  const _initiateDevice = () => {
    console.log("Now Initiating a Device");
    if (!device) GSDKM.mutate();
    return;
  };

  return {
    device,
    call,
    methods: {
      initiateDevice: _initiateDevice,
      makeCall: (token) => _makeCall(token),
      hangUp: () => device?.disconnectAll(),
    },
  };
}
