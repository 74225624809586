import { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SuspensedView from "../components/shared-components/SuspensedView";

const TransferRoutes = lazy(() => import("../modules/transfers"));
const StatementRoutes = lazy(() => import("../modules/statements"));

const Error404 = lazy(() => import("../modules/errors/Error404"));

const PATHS = [
  { path: "/transfers/*", element: <TransferRoutes /> },
  { path: "/statements/*", element: <StatementRoutes /> },
];

export default function AppRoutes() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {PATHS.map(({ path, element }, _index) => (
          <Route path={path} element={<SuspensedView>{element}</SuspensedView>} />
        ))}
        <Route path="/error/404" element={<Error404 />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Routes>
    </BrowserRouter>
  );
}
