// Services
export const BOOKING_API_URL = process.env.REACT_APP_BOOKING_API_URL;
export const COM_API_URL = process.env.REACT_APP_COM_API_URL;
export const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

export const ENDPOINTS = {
  booking: {
    transfer: `${BOOKING_API_URL}/api/transfers`,
  },
  com: {
    sdk: {
      twilio: `${COM_API_URL}/app/sdk/twilio`,
    },
  },
  crm: {
    statement: `${CRM_API_URL}/app/pwa/statements`,
  },
};
