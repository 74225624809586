import { createContext, useState } from "react";
import useCall from "../hooks/useCall";

const AppContext = createContext();
const { Provider } = AppContext;

const AppConfigProvider = ({ children }) => {
  // Locale
  const [locale, setLocale] = useState(localStorage.getItem("locale") ?? "en");
  const changeLocale = (locale) => {
    localStorage.setItem("locale", locale);
    setLocale(locale);
  };

  const callManager = useCall();

  return (
    <Provider
      value={{
        locale,
        changeLocale,
        callManager,
      }}
    >
      {children}
    </Provider>
  );
};
export { AppContext, AppConfigProvider };
