import axios from "axios";
axios.defaults.headers.Accept = "application/json";
axios.interceptors.response.use(
  (response) => {
    if (response.data?.status !== 200) throw response.data?.error;
    return response.data?.data;
  },
  (error) => {
    console.log("API Communication Error");
    throw error;
  }
);
export default axios;
